import PartyDetailsModel from "./PartyDetailsModel"

export default class PartyPlaceModel {

    id: string
    coords: number[]
    name: string
    locationTown: string
    locationStreet: string
    locationHouseNumber: string
    imageLocation: string
    partyNow: PartyDetailsModel
    parties: Array<PartyDetailsModel>
    price: number
    age: number
    description: string
    followers: number

    constructor() {
        this.id = "-1",
        this.coords = [0, 0]
        this.name = ""
        this.locationTown = ""
        this.locationStreet = ""
        this.locationHouseNumber = ""
        this.imageLocation = ""
        this.partyNow = new PartyDetailsModel()
        this.parties = []
        this.price = -1
        this.age = -1
        this.description = ""
        this.followers = -1
    }

    public getLocation(): string {
        return this.locationTown + ", " + this.locationStreet + " " + this.locationHouseNumber;
    }

}