
import { defineComponent } from "vue";
export default defineComponent({
    name: "FatButtonContainer",
    props: {
        background: String,
    },
    computed: {
        background__: function () {
            return {
                background: this.background || "var(--ion-color-light-shade)",
            };
        },
    },
});
