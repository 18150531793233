export default class FriendSearchModalModel {

    open: boolean
    presentingElement: any
    query: string

    constructor() {
        this.open = false;
        this.query = "";
    }

}