import moment from "moment";

export default class TimeUtils {
    
    public static timeFrom(date: Date) {
        const hours = date.getHours()
        const minutes = date.getMinutes()
        if(minutes == 0) {
            return hours + (hours == 0 ? "0" : "") + ":" + minutes + (minutes == 0 ? "0" : "") + "-" + this.__suffixFromNumber(hours)
        } else {
            return hours + ":" + minutes + "-" + this.__suffixFromNumber(minutes)
        }
    }

    private static __suffixFromNumber(number: number): string {
        switch(number) {
            case 0:
            case 10:
            case 40:
            case 50:
                return "től"
            case 20:
            case 30:
                return "tól"
        }
        const numStr = number.toString()
        const lastDigit = numStr.charAt(numStr.length - 1)
        switch(lastDigit) {
            case "1":
            case "2":
            case "4":
            case "5":
            case "7":
            case "9":
                return "től"
            default:
                return "tól"
        }
    }

    public static friendlyDate(date: Date): string {
        moment.locale("hu")
        const dateMoment = moment([date.getFullYear(), date.getMonth(), date.getDate()])
        const today = new Date();
        const todayMoment = moment([today.getFullYear(), today.getMonth(), today.getDate()])
        return dateMoment.calendar(todayMoment, {
            sameDay: '[Ma]',
            nextDay: '[Holnap]',
            nextWeek: '[Ezen hét, ]dddd',
            lastDay: '[Tegnap]',
            lastWeek: '[Múlt] dddd',
            sameElse: 'YYYY. MM. DD.'
        });

    }

    private static __sameDay(dateOne: Date, dateTwo: Date) {
        return dateOne.getFullYear() == dateTwo.getFullYear() &&
            dateOne.getMonth() == dateTwo.getMonth() &&
            dateOne.getDate() == dateTwo.getDate(); 
    }
}