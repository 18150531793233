export default class PlaceSearchModel {

    name: string
    price: number
    age: number
    locationTown: string
    onlyFollowing: boolean
    modalOpen: boolean

    constructor() {
        this.name = ""
        this.price = -1
        this.age = -1
        this.locationTown = ""
        this.onlyFollowing = false;
        this.modalOpen = false
    }

    isActive() {
        return this.name != "" || this.price != -1 || this.locationTown != "" || this.onlyFollowing
    }

    clear() {
        this.name = ""
        this.price = -1
        this.locationTown = ""
        this.onlyFollowing = false
    }
}