import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_fab = _resolveComponent("ion-fab")!

  return (_openBlock(), _createBlock(_component_ion_fab, {
    vertical: "bottom",
    horizontal: "start",
    slot: "fixed",
    class: _normalizeClass(["fat-fab", { fatFabPushup: _ctx.pushup }])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["class"]))
}