import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_ion_tab_bar, {
            slot: "bottom",
            class: "fat-bar",
            translucent: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "trending",
                href: "/tabs/map",
                class: "f"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: $setup.globeOutline,
                    class: "fat-icon"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "explore",
                href: "/tabs/explore-parties"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: $setup.musicalNotesOutline,
                    class: "fat-icon"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "joined",
                href: "/tabs/explore-places"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: $setup.wineOutline,
                    class: "fat-icon"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "profile",
                class: "l",
                onClick: $options.profile
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: $setup.personOutline,
                    class: "fat-icon"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}