
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import store from "@/store";

import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonAvatar,
    IonIcon,
    IonActionSheet,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonToggle,
    IonSelect,
    IonSelectOption,
} from "@ionic/vue";

import {
    ellipsisHorizontal,
    createOutline,
    notificationsOutline,
} from "ionicons/icons";

export default defineComponent({
    name: "ProfileModal",
    components: {
        IonModal,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButtons,
        IonButton,
        IonContent,
        IonGrid,
        IonRow,
        IonCol,
        IonAvatar,
        IonIcon,
        IonActionSheet,
        IonCard,
        IonCardHeader,
        IonCardTitle,
        IonCardSubtitle,
        IonCardContent,
        IonToggle,
        IonSelect,
        IonSelectOption,
    },
    data: () => ({
        actionSheetOpen: false,
        friendActionSheetOpen: false,
        friendRequestActionSheetOpen: false,
        ownActionSheetOpen: false,
        editActionSheetOpen: false,
        versionClicks: 0,
        versionLastClick: new Date("2000-01-01 00:00:00"),
        dev: false,
        publicProfile: true,
        receiveFriendRequestsFrom: "everyone",
        receiveInvitesFrom: "everyone",
        receiveLoginNotifications: "every-time",
        receiveOrganiserNotifications: "everything",
        receiveFriendRequests: "everything",
        receiveSuggestions: "everything",
        receiveMarketingMail: "everything",
    }),
    computed: {
        ...mapGetters([
            "profileModal",
            "friendRequests",
            "friendRequestsOutgoing",
        ]),
        isOwnProfile: function () {
            return this.profileModal.chosenProfile.id == 1;
        },
        appVersion: function () {
            return localStorage.getItem("version");
        },
    },
    methods: {
        dismiss: function () {
            this.actionSheetOpen = false;
            this.friendActionSheetOpen = false;
            this.friendRequestActionSheetOpen = false;
            this.ownActionSheetOpen = false;
            this.editActionSheetOpen = false;
            store.dispatch("setProfileModalOpen", false);
        },
        openActions: function () {
            if (this.profileModal.chosenProfile.isFriendsWith(1)) {
                this.friendActionSheetOpen = true;
            } else if (
                this.friendRequests.includes(
                    this.profileModal.chosenProfile.id
                ) ||
                this.friendRequestsOutgoing.includes(
                    this.profileModal.chosenProfile.id
                )
            ) {
                this.friendRequestActionSheetOpen = true;
            } else if (this.profileModal.chosenProfile.id == 1) {
                this.ownActionSheetOpen = true;
            } else {
                this.actionSheetOpen = true;
            }
        },
        versionClick: function () {
            if (this.versionLastClick.getMinutes() != new Date().getMinutes()) {
                this.versionClicks = 1;
                this.versionLastClick = new Date();
            } else {
                this.versionClicks++;
            }
            if (this.versionClicks == 30) {
                this.versionClicks = 0;
                this.dev = true;
            }
        },
        swkrd: function () {
            (window as any).swkr.unregister();
            window.location.reload();
        },
        rld: function () {
            window.location.reload();
        },
        logout: function () {
            localStorage.removeItem("app-key");
            window.location.reload();
        },
    },
    setup() {
        const friendActionSheet = [
            {
                text: "Barát eltávolítása",
                role: "destructive",
                handler: () => {
                    console.log("Eltávolítva");
                },
            },
            {
                text: "Letiltás",
                role: "destructive",
                handler: () => {
                    console.log("Letiltás");
                },
            },
            {
                text: "Jelentés",
                role: "destructive",
                handler: () => {
                    console.log("Bejelentve");
                },
            },
            {
                text: "Mégsem",
                role: "cancel",
                handler: () => {
                    console.log("Cancel clicked");
                },
            },
        ];
        const actionSheet = [
            {
                text: "Barátnak jelölés",
                handler: () => {
                    console.log("Jelölve");
                },
            },
            {
                text: "Letiltás",
                role: "destructive",
                handler: () => {
                    console.log("Letiltás");
                },
            },
            {
                text: "Jelentés",
                role: "destructive",
                handler: () => {
                    console.log("Bejelentve");
                },
            },
            {
                text: "Mégsem",
                role: "cancel",
                handler: () => {
                    console.log("Cancel clicked");
                },
            },
        ];
        const friendRequestActionSheet = [
            {
                text: "Letiltás",
                role: "destructive",
                handler: () => {
                    console.log("Letiltás");
                },
            },
            {
                text: "Jelentés",
                role: "destructive",
                handler: () => {
                    console.log("Bejelentve");
                },
            },
            {
                text: "Mégsem",
                role: "cancel",
                handler: () => {
                    console.log("Cancel clicked");
                },
            },
        ];
        const ownActionSheet = [
            {
                text: "Kijelentkezés",
                role: "destructive",
                handler: () => {
                    console.log("Kijelentkezés");
                },
            },
            {
                text: "Fiók törlése",
                role: "destructive",
                handler: () => {
                    console.log("Törlés");
                },
            },
            {
                text: "Mégsem",
                role: "cancel",
                handler: () => {
                    console.log("Cancel clicked");
                },
            },
        ];
        const editActionSheet = [
            {
                text: "Profilkép feltöltése",
                handler: () => {
                    console.log("Profilkép szerkesztése");
                },
            },
            {
                text: "Név szerkesztése",
                handler: () => {
                    console.log("Névváltás");
                },
            },
            {
                text: "Mégsem",
                role: "cancel",
                handler: () => {
                    console.log("Cancel clicked");
                },
            },
        ];
        return {
            actionSheet,
            friendActionSheet,
            friendRequestActionSheet,
            ownActionSheet,
            editActionSheet,
            ellipsisHorizontal,
            createOutline,
            notificationsOutline,
        };
    },
});
