import Vuex from "vuex";

import PartyDetailsModalModel from "./models/PartyDetailsModalModel";
import PartyDetailsModel from "./models/PartyDetailsModel";
import PartySearchModel from "./models/PartySearchModel";
import OSM from "ol/source/OSM";
import { transform as transformCoords } from "ol/proj";
const SOURCE_PROJECTION = "EPSG:4326", OSM_PROJECTION = new OSM().getProjection();
import PartyPlaceModel from "./models/PartyPlaceModel";
import TrendingPageModel from "./models/TrendingPageModel";
import TownsModalModel from "./models/TownsModalModel";
import TownModel from "./models/TownModel";
import { Map as GeoMap, Overlay } from "ol";
import TrendingSearchModalModel from "./models/TrendingSearchModalModel";
import ProfileModel from "./models/ProfileModel";
import FriendsModalModel from "./models/FriendsModalModel";
import ProfileModalModel from "./models/ProfileModalModel";
import FriendSearchModalModel from "./models/FriendSearchModalModel";
import PlaceSearchModel from "./models/PlaceSearchModel";
import PlaceDetailsModalModel from "./models/PlaceDetailsModalModel";
import PartyJoinModalModel from "./models/PartyJoinModalModel";
import InviteFriendsModalModel from "./models/InviteFriendsModalModel";

export default new Vuex.Store({
    state: {
        partyDetailsModal: new PartyDetailsModalModel(),
        availableParties: Array<PartyDetailsModel>(
            ((): PartyDetailsModel => {
                const party = new PartyDetailsModel();
                party.id = 0
                party.title = "UV Color party"
                party.locationTown = "Budapest"
                party.locationStreet = "József Attila u."
                party.locationHouseNumber = "12"
                party.timeFrom = new Date(Date.parse("2023-06-11T19:00:00.000000Z"))
                party.timeTo = new Date(Date.parse("2023-06-11T02:30:00.000000Z"))
                party.imageLocation = "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                party.description = `Véget vetünk a szürke hétköznapoknak…!👀😜\n\nTörjenek elő újra a színek Budapest városában!\n\nEgy este, ahol az UV festékek segítségével megmutathatod mindenkinek, milyen színes egyéniség is vagy! 🤩\n\nJó szórakozást kívánunk!`
                party.price = 2500;
                return party;
            })(),
            ((): PartyDetailsModel => {
                const party = new PartyDetailsModel();
                party.id = 1
                party.title = "DJ Cube tavasznyitó"
                party.locationTown = "Budapest"
                party.locationStreet = "Neumann u."
                party.locationHouseNumber = "16"
                party.timeFrom = new Date(Date.parse("2023-06-12T20:30:00.000000Z"))
                party.timeTo = new Date(Date.parse("2023-06-12T03:00:00.000000Z"))
                party.imageLocation = "https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?cs=srgb&dl=pexels-vishnu-r-nair-1105666.jpg&fm=jpg"
                party.description = `Gyere és kezd a tavaszt te is DJ CUBE legjobb mixeivel.😎\n\nÉjfél előtt minden belépőt féláron adunk!`
                party.price = 1000;
                party.age = 16;
                return party;
            })(),
            ((): PartyDetailsModel => {
                const party = new PartyDetailsModel();
                party.id = 2
                party.title = "Retro party"
                party.locationTown = "Budapest"
                party.locationStreet = "Jázmin u."
                party.locationHouseNumber = "22"
                party.timeFrom = new Date(Date.parse("2023-06-13T20:00:00.000000Z"))
                party.timeTo = new Date(Date.parse("2023-06-13T02:30:00.000000Z"))
                party.imageLocation = "https://images.pexels.com/photos/154147/pexels-photo-154147.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                party.description = `Te is megőrülsz a 90-es 2000-es évek zenéiért?\n\nAkkor neked is itt a helyed!🥳\n\nGyere és utazzunk vissza együtt az időbe egy éjszaka erejéig.`
                party.price = 3000;
                return party;
            })(),
            ((): PartyDetailsModel => {
                const party = new PartyDetailsModel();
                party.id = 3
                party.title = "Budapest fesztivál"
                party.locationTown = "Budapest"
                party.locationStreet = "Margit-sziget"
                party.locationHouseNumber = ""
                party.timeFrom = new Date(Date.parse("2023-06-14T08:00:00.000000Z"))
                party.timeTo = new Date(Date.parse("2023-06-14T21:00:00.000000Z"))
                party.imageLocation = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3acA-0JA3zUXmuYsWS2X__LXgdLp-ol7uuQ&usqp=CAU"
                party.description = `Szereted a több napig tartó fesztiválokat?\n\nAkkor a Budapest fesztiválon van a helyed.\n\nTöbb napig tartó feledhetetlen élmények sorozata vár rád. Koncertek, bulik, remek hangulat.\n\nOtt a helyed!🥳`
                party.price = 15000;
                return party;
            })(),
            ((): PartyDetailsModel => {
                const party = new PartyDetailsModel();
                party.id = 4
                party.title = "DJ Fleber koncert"
                party.locationTown = "Budapest"
                party.locationStreet = "Petőfi Sándor u."
                party.locationHouseNumber = "73"
                party.timeFrom = new Date(Date.parse("2023-06-15T20:00:00.000000Z"))
                party.timeTo = new Date(Date.parse("2023-06-15T02:00:00.000000Z"))
                party.imageLocation = "https://www.collinsdictionary.com/images/full/concert_295115348.jpg"
                party.description = `Szeretnél egy jót bulizni a hétvégén?\n\nGyere hozzánk szombat este, mert DJ FLEBER elhozza neked a legjobb hangulatot.`
                party.price = 2500;
                return party;
            })(),
            ((): PartyDetailsModel => {
                const party = new PartyDetailsModel();
                party.id = 5
                party.title = "Fények éjszakája"
                party.locationTown = "Budapest"
                party.locationStreet = "Kossuth Lajos u."
                party.locationHouseNumber = "48"
                party.timeFrom = new Date(Date.parse("2023-06-16T19:00:00.000000Z"))
                party.timeTo = new Date(Date.parse("2023-06-16T07:03:00.000000Z"))
                party.imageLocation = "https://images.squarespace-cdn.com/content/v1/5abff79655b02ce1f785fddd/1554691968970-AAAJGCZR8AVA835891HD/PSFix_20190125_150305.jpeg?format=2500w"
                party.description = `Szeretéd a júniust egy fergeteges bulival kezdeni? Szeretsz a diszkófényekben táncolni?\n\n Akkor itt a helyed.\n Gyere és bulizz velünk szombat éjszaka a legjobb zenékre.`
                party.price = 1000;
                return party;
            })()
        ),
        partySearch: new PartySearchModel(),
        trendingPage: new TrendingPageModel(),
        trendingPlaces: Array<PartyPlaceModel>(
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id ="0";
                partyPlace.coords = [47.4842202, 19.0607723];
                partyPlace.name = "Maverick Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipPko29BoKr61m7db5WSGzAwygjyaUK19E-ck7ow=w408-h306-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "Lónyay u."
                partyPlace.locationHouseNumber = "13"
                partyPlace.price = 0;
                partyPlace.followers = 150;
                const party = new PartyDetailsModel();
                party.id = 0;
                party.title = "Karaoke este";
                party.locationTown = partyPlace.locationTown
                party.locationStreet = partyPlace.locationStreet
                party.locationHouseNumber = partyPlace.locationHouseNumber
                party.timeFrom = new Date("2023-06-11T20:00:00.000000Z")
                party.timeTo = new Date("2023-06-11T23:00:00.000000Z");
                party.imageLocation = "https://s.abcnews.com/images/fivethirtyeight/210512_538_karaoke_hpMain_16x9_1600.jpg"
                party.description = "A kedvenc retro számaid egész este. Énekeljük őket együtt!"
                party.price = 0
                partyPlace.partyNow = party;
                
                const other = new PartyDetailsModel();
                other.id = 0;
                other.title = "Stand up show";
                other.locationTown = partyPlace.locationTown
                other.locationStreet = partyPlace.locationStreet
                other.locationHouseNumber = partyPlace.locationHouseNumber
                other.timeFrom = new Date("2023-06-15T18:30:00.000000Z")
                other.timeTo = new Date("2023-06-15T19:25:00.000000Z");
                other.imageLocation = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgLjLEdlJ8-4_RcfSpzZadLBTadq7JLCg_Rg&usqp=CAU"
                other.description = "Egy egyedülálló stand up comedy show. Előadja Nemléteczky Ottó."
                other.price = 2500
                const other2 = new PartyDetailsModel();
                other2.id = 1;
                other2.title = "DJ Swann";
                other2.locationTown = partyPlace.locationTown
                other2.locationStreet = partyPlace.locationStreet
                other2.locationHouseNumber = partyPlace.locationHouseNumber
                other2.timeFrom = new Date("2023-06-20T22:10:00.000000Z")
                other2.timeTo = new Date("2023-06-21T04:00:00.000000Z");
                other2.imageLocation = "https://static.dw.com/image/60290826_101.jpg"
                other2.description = "Táncold el az estét DJ Swannal, aki csak a legnépszerűbb pop számokat játssza."
                other2.price = 0
                const other3 = new PartyDetailsModel();
                other3.id = 2;
                other3.title = "DJ Liam";
                other3.locationTown = partyPlace.locationTown
                other3.locationStreet = partyPlace.locationStreet
                other3.locationHouseNumber = partyPlace.locationHouseNumber
                other3.timeFrom = new Date("2023-06-29T23:30:00.000000Z")
                other3.timeTo = new Date("2023-06-29T02:00:00.000000Z");
                other3.imageLocation = "https://legalisdj.hu/assets/img/legalisdj-bg-6.jpg"
                other3.description = "Csatlakozz DJ Liam-hoz és töltsd rockkal az estét!"
                other3.age = 16
                other3.price = 0
                partyPlace.parties = [other, other2, other3];
                
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "1";
                partyPlace.coords = [47.4808788, 19.0663199];
                partyPlace.name = "Jolly Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipNvJCSnV7lpgv8UY9lFIytBAvlvZ5Rt36uMUVf-=w408-h306-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "Boráros tér"
                partyPlace.locationHouseNumber = "1"
                partyPlace.price = 0
                partyPlace.followers = 210
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "2";
                partyPlace.coords = [47.4791816, 19.0536032];
                partyPlace.name = "Műhely Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOSFl9Vlerf9bQ2X702VOiNY0jnGIIvJfvdzMk9=w520-h240-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "Budafoki út"
                partyPlace.locationHouseNumber = "29"
                partyPlace.price = 0
                partyPlace.followers = 89
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "3";
                partyPlace.coords = [47.4781799, 19.051045];
                partyPlace.name = "Mag Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOAU2fgEG7pQBiuJBQeNDhkWPa_4V4R29yvsrlP=w426-h240-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "Bercsényi u."
                partyPlace.locationHouseNumber = "9"
                partyPlace.price = 0
                partyPlace.followers = 243
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "4";
                partyPlace.coords = [47.4760604, 19.0388104];
                partyPlace.name = "Pingvin Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipP4g_wtKAvGxviaumzxwwZr7e1-27mp6NrSMwUn=w408-h272-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "Bocskai út"
                partyPlace.locationHouseNumber = "33"
                partyPlace.price = 0
                partyPlace.followers = 174
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "5";
                partyPlace.coords = [47.4921228, 19.0565541];
                partyPlace.name = "Kelta Söröző";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipO6Yl0OTNJ0c9Pgp1eFr-gy4YXHB9jpX9xW_u_6=w426-h240-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "Fereciek tere"
                partyPlace.locationHouseNumber = "5"
                partyPlace.price = 0
                partyPlace.followers = 189
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "6";
                partyPlace.coords = [47.5213561, 19.1673928];
                partyPlace.name = "Corner's pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipMm8TQoN4aAfrE-0qsLKR2BDTO7dHdL7xr5ox4h=w408-h306-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "Rákosi út"
                partyPlace.locationHouseNumber = "114"
                partyPlace.price = 0
                partyPlace.followers = 127
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "7";
                partyPlace.coords = [47.5156755, 19.0592531];
                partyPlace.name = "Megálló Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipPx2sjgXhtNeXuvUzh9Mk3VJXFE0w1bzrQeaxll=w408-h306-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "Váci út"
                partyPlace.locationHouseNumber = "34"
                partyPlace.price = 0
                partyPlace.followers = 204
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "8";
                partyPlace.coords = [47.5586496, 19.0791152];
                partyPlace.name = "Popeye Kocsma";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipN-qTRuxweBkrEhAyXGoBKnd37u9WDNbhoUPNjP=w425-h240-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "Balzsam u."
                partyPlace.locationHouseNumber = "6"
                partyPlace.price = 0
                partyPlace.followers = 87
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "9";
                partyPlace.coords = [47.5701625, 19.0182681];
                partyPlace.name = "Stingers Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipNdbOW0W7klsf9ad-nhxHSlctoB2fxhKbNc-3iK=w426-h240-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "Bécsi út"
                partyPlace.locationHouseNumber = "310"
                partyPlace.price = 0
                partyPlace.followers = 185
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "10";
                partyPlace.coords = [47.5067927, 19.036986];
                partyPlace.name = "Polo Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipO8ZM-haPkMWYL29ywpQudPvdnTcet-7AUM5ZK0=w408-h408-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "Batthyány u."
                partyPlace.locationHouseNumber = "4"
                partyPlace.price = 0
                partyPlace.followers = 231
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "11";
                partyPlace.coords = [47.5015929, 19.0672814];
                partyPlace.name = "Winners Sports Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOhNMWsjxvOdZ73UJHMNQMGuCLBTlvgCbS-rTyW=w408-h306-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "Erzsébet krt."
                partyPlace.locationHouseNumber = "40"
                partyPlace.price = 0
                partyPlace.followers = 247
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "12";
                partyPlace.coords = [47.4948987, 19.0706492];
                partyPlace.name = "The Pointer Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipMv-x6YgI6J0McOtPsZqnE9x9jQtQGFsJHIuvVT=w426-h240-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "József krt."
                partyPlace.locationHouseNumber = "15"
                partyPlace.price = 0
                partyPlace.followers = 376
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "13";
                partyPlace.coords = [47.4988452, 19.064488];
                partyPlace.name = "Pajta Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipNaC50OtRED_RmvAeeWMqhFuw63qOoOpDNHFNZU=w408-h408-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "Nyár u."
                partyPlace.locationHouseNumber = "34"
                partyPlace.price = 0
                partyPlace.followers = 195
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "14";
                partyPlace.coords = [47.5004344, 19.10874];
                partyPlace.name = "Stadion Söröző";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOZYwDBWB7QggFbI0n0HLR6NQ0BfEE6CJxv7QC3=w408-h306-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "Kerepesi út"
                partyPlace.locationHouseNumber = "24"
                partyPlace.price = 0
                partyPlace.followers = 105
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "15";
                partyPlace.coords = [47.5037896, 19.1351587];
                partyPlace.name = "Zöld Kancsó Söröző";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOp728_OqYWFoXOoEsEO5AkTZwfqaj1bjyH98vP=w408-h544-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "Örs vezér tere"
                partyPlace.locationHouseNumber = "1"
                partyPlace.price = 0
                partyPlace.followers = 306
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "16";
                partyPlace.coords = [47.5953485, 19.0503059];
                partyPlace.name = "Orsi Söröző";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipMm8QPF0IIu4JWJmWIbSgnprGVh5YtRPO2uaqLE=w408-h306-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "Hollós Korvin Lajos u."
                partyPlace.locationHouseNumber = "4"
                partyPlace.price = 0
                partyPlace.followers = 71
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "17";
                partyPlace.coords = [47.4104039, 19.0555257];
                partyPlace.name = "Rózsa Söröző";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOd4uPaz3dN1E-LsAUlGCl1jYFKt6zvIogp4xc3=w408-h306-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "II. Rákóczi Ferenc út"
                partyPlace.locationHouseNumber = "207"
                partyPlace.price = 0
                partyPlace.followers = 82
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "18";
                partyPlace.coords = [47.4112509, 19.0880703];
                partyPlace.name = "Söröm Tanya";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipNsS227fGm3xjBKlRILH7jzBZUNHTxwmKg7Fe7I=w426-h240-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "Szent István út"
                partyPlace.locationHouseNumber = "157"
                partyPlace.price = 0
                partyPlace.followers = 92
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "19";
                partyPlace.coords = [47.4853758, 19.2170355];
                partyPlace.name = "Darling Söröző";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOy7pAuBtLUoeTXHtmUcDFKqQkrkfQ6ie668rtV=w426-h240-k-no"
                partyPlace.locationTown = "Budapest"
                partyPlace.locationStreet = "501. u."
                partyPlace.locationHouseNumber = "2"
                partyPlace.price = 0
                partyPlace.followers = 116
                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "event_967dbe6d_51f3_4adc_92ca_21046b71dd4e";
                partyPlace.coords = [46.9160348, 19.6761623];
                partyPlace.name = "Gyöngy Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOpQjNwAgf-PLhdFpEO3zxZPLRGQHxB5j6_Shxr=w408-h544-k-no"
                partyPlace.locationTown = "Kecskemét"
                partyPlace.locationStreet = "Aradi vértanúk tere"
                partyPlace.locationHouseNumber = "3"
                partyPlace.price = 0;
                partyPlace.followers = 80;
                
                
                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "event_2b2782f5_bed0_4a79_a162_841ee076139c";
                partyPlace.coords = [46.9282779, 19.6877874];
                partyPlace.name = "Bomber Pub Kecskemét";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipP6T-pdpBc0KpuJI0_DQp9eGcuPNFx4EApGXXNZ=w408-h306-k-no"
                partyPlace.locationTown = "Kecskemét"
                partyPlace.locationStreet = "Vacsi köz"
                partyPlace.locationHouseNumber = "60"
                partyPlace.price = 0;
                partyPlace.followers = 94;
                
                
                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "event_7e266001_df20_4921_8b00_0a884ad561d4";
                partyPlace.coords = [46.9061014, 19.7038311];
                partyPlace.name = "Club Koffein Bár";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipM7bQIGGEHlCMUGVf6NHXm6GsPjBRCQTEKlJSW1=w426-h240-k-no"
                partyPlace.locationTown = "Kecskemét"
                partyPlace.locationStreet = "Kuruc krt."
                partyPlace.locationHouseNumber = "21"
                partyPlace.price = 0;
                partyPlace.followers = 51;
                
                
                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "event_e65931fd_7ef6_44fc_8371_36ccc72a55aa";
                partyPlace.coords = [46.9279327, 19.5777696];
                partyPlace.name = "Forest Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipPgJtQ-MUnFkyNZ1IzvtGS13d949dMid-7bYqxM=w426-h240-k-no"
                partyPlace.locationTown = "Kecskemét"
                partyPlace.locationStreet = "Kossuth Lajos u."
                partyPlace.locationHouseNumber = "150"
                partyPlace.price = 0;
                partyPlace.followers = 71;
                
                
                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "event_8b4d330e_2266_40cc_a7f6_ac42e6a0dff5";
                partyPlace.coords = [46.9054728, 19.6950795];
                partyPlace.name = "Red Rock Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipPN1b_A1qSpSdXH7XiRgeQ1M9Kl6aL-4ZtG9UQQ=w408-h544-k-no"
                partyPlace.locationTown = "Kecskemét"
                partyPlace.locationStreet = "Erkel u."
                partyPlace.locationHouseNumber = "1"
                partyPlace.price = 0;
                partyPlace.followers = 87;
                
                
                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "event_f9b70753_303c_4c06_8334_9fe689ebf3dc";
                partyPlace.coords = [46.9068466, 19.6941211];
                partyPlace.name = "Szakkör Craft Beer Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipO9PHSBKXuMb3bSHeXB2rAacCZiQVBWFOOYTG4x=w408-h326-k-no"
                partyPlace.locationTown = "Kecskemét"
                partyPlace.locationStreet = "Kéttemplom köz"
                partyPlace.locationHouseNumber = "10"
                partyPlace.price = 0;
                partyPlace.followers = 63;
                
                
                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "event_847447f9_6e64_4dc9_9f87_a2afc5fbe025";
                partyPlace.coords = [46.9076867, 19.6960234];
                partyPlace.name = "Black Cat Pub";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipMdovUgKss6RyqjTeNpAG_R5QRb6GSZVS33QwMb=w408-h306-k-no"
                partyPlace.locationTown = "Kecskemét"
                partyPlace.locationStreet = "Csányi János krt."
                partyPlace.locationHouseNumber = "6"
                partyPlace.price = 0;
                partyPlace.followers = 103;
                
                
                partyPlace.parties = [];

                return partyPlace;
            })(),
            ((): PartyPlaceModel => {
                const partyPlace = new PartyPlaceModel();
                partyPlace.id = "event_72449365_2e89_4209_829c_55e3cc9898c4";
                partyPlace.coords = [46.9154896, 19.6689165];
                partyPlace.name = "Popeye";
                partyPlace.imageLocation = "https://lh5.googleusercontent.com/p/AF1QipOquN0OOdDf7dN16rz__Fb9rVpWUiSVP2N_AiSQ=w426-h240-k-no"
                partyPlace.locationTown = "Kecskemét"
                partyPlace.locationStreet = "Pajzs u."
                partyPlace.locationHouseNumber = "2"
                partyPlace.price = 0;
                partyPlace.followers = 32;
                
                
                partyPlace.parties = [];

                return partyPlace;
            })()
        ),
        townsModal: new TownsModalModel(),
        towns: Array<TownModel>(
            ((): TownModel => {
                const model = new TownModel();
                model.id = 0;
                model.name = "Budapest";
                model.coords = [47.4813602, 18.990219]
                model.optimalZoom = 11
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel()
                model.id = 69
                model.name = "Kecskemét"
                model.coords = [46.8998708, 19.6686248]
                model.optimalZoom = 11
                return model
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 1;
                model.name = "Kaposvár";
                model.coords = [46.3705281, 17.7461174]
                model.optimalZoom = 12
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 2;
                model.name = "Pécs";
                model.coords = [46.0778856, 18.1104973]
                model.optimalZoom = 11
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 3;
                model.name = "Szeged";
                model.coords = [46.232941, 20.0003844]
                model.optimalZoom = 11
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 5;
                model.name = "Szolnok";
                model.coords = [47.18055, 20.043588]
                model.optimalZoom = 11
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 6;
                model.name = "Debrecen";
                model.coords = [47.5310609, 21.520098]
                model.optimalZoom = 11
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 7;
                model.name = "Nyíregyháza";
                model.coords = [47.9425403, 21.5608251]
                model.optimalZoom = 11
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 8;
                model.name = "Miskolc";
                model.coords = [48.0892992, 20.5356655]
                model.optimalZoom = 11
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 9;
                model.name = "Eger";
                model.coords = [47.9292757, 20.32137]
                model.optimalZoom = 12
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 10;
                model.name = "Székesfehérvár";
                model.coords = [47.1946824, 18.3011679]
                model.optimalZoom = 11
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 11;
                model.name = "Győr";
                model.coords = [47.6695253, 17.5886451]
                model.optimalZoom = 12
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 12;
                model.name = "Szombathely";
                model.coords = [47.2144213, 16.5426206]
                model.optimalZoom = 12
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 13;
                model.name = "Siófok";
                model.coords = [46.8952874, 18.0022121]
                model.optimalZoom = 12
                return model;
            })(),
            ((): TownModel => {
                const model = new TownModel();
                model.id = 14;
                model.name = "Badacsonytomaj";
                model.coords = [46.7904924, 17.4812566]
                model.optimalZoom = 13
                return model;
            })(),
        ),
        trendingSearchModal: new TrendingSearchModalModel(),
        modalsOpen: new Array<string>(),
        userId: 1,
        friendRequests: Array.from([6]),
        friendRequestsOutgoing: Array.from([4]),
        users: new Map<number, ProfileModel>([
            [
                1,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 1
                    profile.firstName = "Nagy"
                    profile.lastName = "László"
                    profile.username = "nlaszlo"
                    profile.birthday = new Date("2001-04-14T00:00:00.000000Z")
                    profile.friends = new Array<number>(2, 3, 5, 8, 9, 10, 11, 12, 13, 14, 15, 16)
                    return profile;
                })()
            ],
            [
                2,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 2
                    profile.firstName = "Kiss"
                    profile.lastName = "Mariann"
                    profile.username = "kmar76"
                    profile.birthday = new Date("1998-03-19T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                3,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 3
                    profile.firstName = "Májusi"
                    profile.lastName = "Lili"
                    profile.username = "mlil"
                    profile.birthday = new Date("2002-07-21T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                4,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 4
                    profile.firstName = "Nagy"
                    profile.lastName = "Márta"
                    profile.username = "nmarta5"
                    profile.birthday = new Date("1995-05-18T00:00:00.000000Z")
                    return profile;
                })()
            ],
            [
                5,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 5
                    profile.firstName = "Ottó"
                    profile.lastName = "József"
                    profile.username = "ojozso12"
                    profile.birthday = new Date("1977-07-12T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                6,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 6
                    profile.firstName = "Lóffy"
                    profile.lastName = "Ottó"
                    profile.username = "ottol"
                    profile.birthday = new Date("1968-09-24T00:00:00.000000Z")
                    return profile;
                })()
            ],
            [
                7,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 7
                    profile.firstName = "Szemes"
                    profile.lastName = "Dóra"
                    profile.username = "szemesd"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    return profile;
                })()
            ],
            [
                8,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 8
                    profile.firstName = "Lovas"
                    profile.lastName = "Hanna"
                    profile.username = "lhanni"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                9,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 9
                    profile.firstName = "Nyári"
                    profile.lastName = "Jani"
                    profile.username = "nyjani"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                10,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 10
                    profile.firstName = "Pál"
                    profile.lastName = "Miklós"
                    profile.username = "pmik"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                11,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 11
                    profile.firstName = "Arany"
                    profile.lastName = "Hajnalka"
                    profile.username = "ahajn"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                12,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 12
                    profile.firstName = "Kun"
                    profile.lastName = "Lilla"
                    profile.username = "klili"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                13,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 13
                    profile.firstName = "Horváth"
                    profile.lastName = "Robi"
                    profile.username = "hrobs"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                14,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 14
                    profile.firstName = "Német"
                    profile.lastName = "Bori"
                    profile.username = "nbori"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                15,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 15
                    profile.firstName = "Tímár"
                    profile.lastName = "Júlia"
                    profile.username = "tjulia"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                16,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 16
                    profile.firstName = "Fóth"
                    profile.lastName = "Balázs"
                    profile.username = "fothb"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                17,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 16
                    profile.firstName = "Kiss"
                    profile.lastName = "Júlia"
                    profile.username = "kssjuli"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
            [
                18,
                ((): ProfileModel => {
                    const profile = new ProfileModel();
                    profile.id = 16
                    profile.firstName = "Bíró"
                    profile.lastName = "Júlia"
                    profile.username = "birjl"
                    profile.birthday = new Date("1998-11-28T00:00:00.000000Z")
                    profile.friends = new Array<number>(-1, 1);
                    return profile;
                })()
            ],
        ]),
        friendsModal: new FriendsModalModel(),
        profileModal: new ProfileModalModel(),
        friendSearchModal: new FriendSearchModalModel(),
        placeSearch: new PlaceSearchModel(),
        placeDetailsModal: new PlaceDetailsModalModel(),
        followedPlaces: Array.from([3, 4]),
        joinedParties: Array.from([0, 1]),
        partyJoinModal: new PartyJoinModalModel(),
        inviteFriendsModal: new InviteFriendsModalModel(),
    },
    getters: {
        availableParties(state) {
            return state.availableParties;
        },
        partyDetailsModal(state) {
            return state.partyDetailsModal;
        },
        partySearch(state) {
            return state.partySearch;
        },
        trendingPage(state) {
            return state.trendingPage;
        },
        trendingPlaces(state) {
            return state.trendingPlaces;
        },
        townsModal(state) {
            return state.townsModal;
        },
        towns(state) {
            return state.towns;
        },
        trendingSearchModal(state) {
            return state.trendingSearchModal;
        },
        modalsOpen(state) {
            return state.modalsOpen
        },
        userId(state) {
            return state.userId;
        },
        friendRequests(state) {
            return state.friendRequests;
        },
        friendRequestsOutgoing(state) {
            return state.friendRequestsOutgoing;
        },
        users(state) {
            return state.users;
        },
        friendsModal(state) {
            return state.friendsModal;
        },
        profileModal(state) {
            return state.profileModal;
        },
        friendSearchModal(state) {
            return state.friendSearchModal;
        },
        placeSearch(state) {
            return state.placeSearch;
        },
        placeDetailsModal(state) {
            return state.placeDetailsModal;
        },
        followedPlaces(state) {
            return state.followedPlaces;
        },
        joinedParties(state) {
            return state.joinedParties;
        },
        partyJoinModal(state) {
            return state.partyJoinModal;
        },
        inviteFriendsModal(state) {
            return state.inviteFriendsModal;
        }
    },
    mutations: {
        addAvailableParties(state, parties: Array<PartyDetailsModel>) {
            state.availableParties = state.availableParties.concat(parties);
        },
        setAvailableParties(state, parties: Array<PartyDetailsModel>) {
            state.availableParties = parties;
        },
        setPartyDetailsModalOpen(state, boolean: boolean) {
            state.partyDetailsModal.open = boolean;
        },
        setPartyDetailsModalContent(state, party: PartyDetailsModel) {
            state.partyDetailsModal.partyDetails = party;
        },
        setPartySearch(state, search: PartySearchModel) {
            state.partySearch = search;
        },
        setPartySearchName(state, name: string) {
            state.partySearch.name = name;
        },
        setPartySearchDateFrom(state, date: Date) {
            state.partySearch.dateFrom = date;
        },
        setPartySearchPrice(state, price: number) {
            state.partySearch.price = price;
        },
        setPartySearchAge(state, age: number) {
            state.partySearch.age = age;
        },
        setPartySearchLocationTown(state, town: string) {
            state.partySearch.locationTown = town;
        },
        setPartySearchOnlyJoined(state, only: boolean) {
            state.partySearch.onlyJoined = only;
        },
        setPartySearchModalOpen(state, open: boolean) {
            state.partySearch.modalOpen = open;
        },
        clearPartySearchModal(state, ignored: any) {
            state.partySearch.clear();
        },
        setTrendingPageMap(state, map: GeoMap) {
            state.trendingPage.map = map;
        },
        setTrendingPagePopup(state, popup: Overlay) {
            state.trendingPage.popup = popup;
        },
        setTrendingPageCoords(state, coords: number[]) {
            const transformedCoords = 
                transformCoords([coords[1], coords[0]], SOURCE_PROJECTION, OSM_PROJECTION);
            state.trendingPage.map.getView().animate({
                    center: transformedCoords,
                });
        },
        setTrendingPageZoom(state, zoom: number) {
            state.trendingPage.map.getView().animate({
                zoom
            });
        },
        setTrendingPageSelectedPlace(state, place: PartyPlaceModel | undefined) {
            if (place == undefined) {
                state.trendingPage.popup.setPosition(undefined);
                return;
            }
            const transformedCoords = transformCoords([place.coords[1], place.coords[0]], SOURCE_PROJECTION, OSM_PROJECTION);
            state.trendingPage.popup.setPosition(transformedCoords);
            state.trendingPage.selectedPlace = place;
        },
        addTrendingPlaces(state, places: Array<PartyPlaceModel>) {
            state.trendingPlaces = state.trendingPlaces.concat(places);
        },
        setTrendingPlaces(state, places: Array<PartyPlaceModel>) {
            state.trendingPlaces = places;
        },
        setTownsModalOpen(state, open: boolean) {
            state.townsModal.open = open;
        },
        setTrendingSearchModalOpen(state, open: boolean) {
            state.trendingSearchModal.open = open;
        },
        addOpenModal(state, closerActionName: string) {
            state.modalsOpen.push(closerActionName);
        },
        removeOpenModal(state, closerActionName: string) {
            state.modalsOpen = state.modalsOpen.filter((action) => action != closerActionName);
        },
        setFriendsModalOpen(state, open: boolean) {
            state.friendsModal.open = open;
        },
        setProfileModalOpen(state, open: boolean) {
            state.profileModal.open = open;
        },
        setProfileModalPresentingElement(state, element: any) {
            state.profileModal.presentingElement = element;
        },
        setProfileModalChosenProfile(state, profile: ProfileModel) {
            state.profileModal.chosenProfile = profile;
        },
        setFriendSearchModalOpen(state, open: boolean) {
            state.friendSearchModal.open = open;
        },
        setFriendSearchModalPresentingElement(state, element: any) {
            state.friendSearchModal.presentingElement = element;
        },
        setFriendSearchModalQuery(state, query: string) {
            state.friendSearchModal.query = query;
        },
        setPlaceSearchName(state, name: string) {
            state.placeSearch.name = name;
        },
        setPlaceSearchPrice(state, price: number) {
            state.placeSearch.price = price;
        },
        setPlaceSearchAge(state, age: number) {
            state.placeSearch.age = age;
        },
        setPlaceSearchLocationTown(state, town: string) {
            state.placeSearch.locationTown = town;
        },
        setPlaceSearchOnlyFollowing(state, only: boolean) {
            state.placeSearch.onlyFollowing = only;
        },
        setPlaceSearchModalOpen(state, open: boolean) {
            state.placeSearch.modalOpen = open;
        },
        clearPlaceSearchModal(state, any: any) {
            state.placeSearch.clear()
        },
        setPlaceDetailsModalContent(state, content: PartyPlaceModel) {
            state.placeDetailsModal.placeDetails = content;
        },
        setPlaceDetailsModalOpen(state, open: boolean) {
            state.placeDetailsModal.open = open;
        },
        setPartyJoinModalSelectedParty(state, party: PartyDetailsModel) {
            state.partyJoinModal.selectedParty = party;
        },
        setPartyJoinModalOpen(state, open: boolean) {
            state.partyJoinModal.modalOpen = open;
        },
        setPartyJoinModalPartyModalRef(state, ref: any) {
            state.partyJoinModal.partyModalRef = ref;
        },
        setInviteFriendsModalQuery(state, query: string) {
            state.inviteFriendsModal.query = query;
        },
        setInviteFriendsModalInvitedFriends(state, invitedFriends: Array<number>) {
            state.inviteFriendsModal.invitedFriends = invitedFriends;
        },
        addInviteFriendsModalInvitedFriends(state, invitedFriends: Array<number>) {
            state.inviteFriendsModal.invitedFriends = state.inviteFriendsModal.invitedFriends.concat(invitedFriends);
        },
        removeInviteFriendsModalInvitedFriends(state, friends: Array<number>) {
            state.inviteFriendsModal.invitedFriends = state.inviteFriendsModal.invitedFriends
                .filter((uid: number) => !friends.includes(uid));
        },
        setInviteFriendsModalOpen(state, open: boolean) {
            state.inviteFriendsModal.open = open;
        },
        setInviteFriendsModalPartyJoinModalRef(state, ref: any) {
            state.inviteFriendsModal.partyJoinModalRef = ref;
        }
    },
    actions: {
        addAvailableParties({ commit }, parties: Array<PartyDetailsModel>) {
            commit("addAvailableParties", parties);
        },
        setAvailableParties({commit}, parties: Array<PartyDetailsModel>) {
            commit("setAvailableParties", parties);
        },
        setPartyDetailsModalOpen({ commit }, open: boolean) {
            commit("setPartyDetailsModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setPartyDetailsModalOpen")
        },
        setPartyDetailsModalContent({commit}, party: PartyDetailsModel) {
            commit("setPartyDetailsModalContent", party);
        },
        setPartySearch({commit}, search: PartySearchModel) {
            commit("setPartySearch", search);
        },
        setPartySearchName({commit}, name: string) {
            commit("setPartySearchName", name);
        },
        setPartySearchDateFrom({commit}, date: Date) {
            commit("setPartySearchDateFrom", date);
        },
        setPartySearchPrice({commit}, free: boolean) {
            commit("setPartySearchPrice", free);
        },
        setPartySearchAge({commit}, age: number) {
            commit("setPartySearchAge", age);
        },
        setPartySearchLocationTown({commit}, town: string) {
            commit("setPartySearchLocationTown", town);
        },
        setPartySearchOnlyJoined({commit}, only: boolean) {
            commit("setPartySearchOnlyJoined", only);
        },
        setPartySearchModalOpen({commit}, open: boolean) {
            commit("setPartySearchModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setPartySearchModalOpen")
        },
        clearPartySearchModal({commit}, any: any) {
            commit("clearPartySearchModal", any);
        },
        setTrendingPageMap({commit}, map: GeoMap) {
            commit("setTrendingPageMap", map);
        },
        setTrendingPagePopup({commit}, popup: Overlay) {
            commit("setTrendingPagePopup", popup);
        },
        setTrendingPageCoords({commit}, coords: number[]) {
            commit("setTrendingPageCoords", coords);
        },
        setTrendingPageZoom({commit}, zoom: number) {
            commit("setTrendingPageZoom", zoom);
        },
        setTrendingPageSelectedPlace({commit}, place: PartyPlaceModel) {
            commit("setTrendingPageSelectedPlace", place);
        },
        addTrendingPlaces({commit}, places: Array<PartyPlaceModel>) {
            commit("addTrendingPlaces", places);
        },
        setTrendingPlaces({commit}, places: Array<PartyPlaceModel>) {
            commit("setTrendingPlaces", places);
        },
        setTownsModalOpen({commit}, open: boolean) {
            commit("setTownsModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setTownsModalOpen")
        },
        setTrendingSearchModalOpen({commit}, open: boolean) {
            commit("setTrendingSearchModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setTrendingSearchModalOpen")
        },
        addOpenModal({commit}, closerActionName: string) {
            commit("addOpenModal", closerActionName);
        },
        removeOpenModal({commit}, closerActionName: string) {
            commit("removeOpenModal", closerActionName);
        },
        setFriendsModalOpen({commit}, open: boolean) {
            commit("setFriendsModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setFriendsModalOpen")
        },
        setProfileModalOpen({commit}, open: boolean) {
            commit("setProfileModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setProfileModalOpen")
        },
        setProfileModalPresentingElement({commit}, element: any) {
            commit("setProfileModalPresentingElement", element);
        },
        setProfileModalChosenProfile({commit}, profile: ProfileModel) {
            commit("setProfileModalChosenProfile", profile);
        },
        setFriendSearchModalOpen({commit}, open: boolean) {
            commit("setFriendSearchModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setFriendSearchModalOpen")
        },
        setFriendSearchModalPresentingElement({commit}, element: any) {
            commit("setFriendSearchModalPresentingElement", element);
        },
        setFriendSearchModalQuery({commit}, query: string) {
            commit("setFriendSearchModalQuery", query);
        },
        setPlaceSearchName({commit}, name: string) {
            commit("setPlaceSearchName", name);
        },
        setPlaceSearchPrice({commit}, price: number) {
            commit("setPlaceSearchPrice", price);
        },
        setPlaceSearchAge({commit}, age: number) {
            commit("setPlaceSearchAge", age);
        },
        setPlaceSearchLocationTown({commit}, town: string) {
            commit("setPlaceSearchLocationTown", town);
        },
        setPlaceSearchOnlyFollowing({commit}, only: boolean) {
            commit("setPlaceSearchOnlyFollowing", only);
        },
        setPlaceSearchModalOpen({commit}, open: boolean) {
            commit("setPlaceSearchModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setPlaceSearchModalOpen")
        },
        clearPlaceSearchModal({commit}, any: any) {
            commit("clearPlaceSearchModal", any);
        },
        setPlaceDetailsModalContent({commit}, content: PartyPlaceModel) {
            commit("setPlaceDetailsModalContent", content);
        },
        setPlaceDetailsModalOpen({commit}, open: boolean) {
            commit("setPlaceDetailsModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setPlaceDetailsModalOpen")
        },
        setPartyJoinModalSelectedParty({commit}, party: PartyDetailsModel) {
            commit("setPartyJoinModalSelectedParty", party);
        },
        setPartyJoinModalOpen({commit}, open: boolean) {
            commit("setPartyJoinModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setPartyJoinModalOpen")
        },
        setPartyJoinModalPartyModalRef({commit}, ref: any) {
            commit("setPartyJoinModalPartyModalRef", ref);
        },
        setInviteFriendsModalQuery({commit}, query: string) {
            commit("setInviteFriendsModalQuery", query);
        },
        setInviteFriendsModalInvitedFriends({commit}, invitedFriends: Array<number>) {
            commit("setInviteFriendsModalInvitedFriends", invitedFriends);
        },
        addInviteFriendsModalInvitedFriends({commit}, invitedFriends: Array<number>) {
            commit("addInviteFriendsModalInvitedFriends", invitedFriends);
        },
        removeInviteFriendsModalInvitedFriends({commit}, friends: Array<number>) {
            commit("removeInviteFriendsModalInvitedFriends", friends);
        },
        setInviteFriendsModalOpen({commit}, open: boolean) {
            commit("setInviteFriendsModalOpen", open);
            commit(open ? "addOpenModal" : "removeOpenModal", "setInviteFriendsModalOpen")
        },
        setInviteFriendsModalPartyJoinModalRef({commit}, ref: any) {
            commit("setInviteFriendsModalPartyJoinModalRef", ref);
        }
    },
    modules: {},
});
