import store from ".."

export default class ProfileModel {
    id: number
    firstName: string
    lastName: string
    username: string
    birthday: Date
    friends: Array<number>

    constructor() {
        this.id = -1
        this.firstName = ""
        this.lastName = ""
        this.username = ""
        this.birthday = new Date("2000-01-01 00:00:00")
        this.friends = new Array<number>()
    }

    static fromId(id: number): ProfileModel {
        return store.getters.users.get(id);
    }

    hasBirthdayToday() {
        const now = new Date();
        return (
            this.birthday.getMonth() == now.getMonth() &&
            this.birthday.getDate() == now.getDate()
        );
    }

    getBirthdayFormatted() {
        return this.birthday.toLocaleString("hu-HU", {month: "long"}) + " " + this.birthday.getDate() + ".";
    }

    isFriendsWith(profileId: number) {
        return this.friends.includes(profileId);
    }

    getCommonFriendsWith(profileId: number) {
        const otherProfile: ProfileModel = store.getters.users.get(profileId);
        return this.getCommon(this.friends, otherProfile.friends);
    }

    private getCommon(arr1: Array<number>, arr2: Array<number>) {
        const common = [];                   // Array to contain common elements
        for(let i=0 ; i<arr1.length ; ++i) {
          for(let j=0 ; j<arr2.length ; ++j) {
            if(arr1[i] == arr2[j]) {       // If element is in both the arrays
              common.push(arr1[i]);        // Push to common array
            }
          }
        }
        
        return common;                     // Return the common elements
      }
}