import PartyPlaceModel from "./PartyPlaceModel"

export default class PlaceDetailsModalModel {
    open: boolean
    placeDetails: PartyPlaceModel

    constructor() {
        this.open = false;
        this.placeDetails = new PartyPlaceModel();
    }
}