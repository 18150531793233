import TownModel from "./TownModel";

export default class TownsModalModel {

    open: boolean

    constructor() {
        this.open = false;
    }

}