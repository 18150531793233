
import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
    IonSearchbar,
    IonLabel,
    IonItemDivider,
    IonAvatar,
    IonCheckbox,
    IonItem,
    IonItemGroup,
    IonList,
} from "@ionic/vue";
import { close, checkmark } from "ionicons/icons";
import { defineComponent } from "vue";
import store from "../store";
import { mapGetters } from "vuex";
import ProfileModel from "@/store/models/ProfileModel";

export default defineComponent({
    name: "InviteFriendsModal",
    components: {
        IonModal,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonButton,
        IonContent,
        IonSearchbar,
        IonLabel,
        IonItemDivider,
        IonAvatar,
        IonCheckbox,
        IonItem,
        IonItemGroup,
        IonList,
    },
    data() {
        return {
            content: "Content",
            q: "",
        };
    },
    computed: {
        ...mapGetters(["inviteFriendsModal"]),
        friendsInvited: function () {
            return store.getters.users
                .get(1)
                .friends.filter((uid: number) =>
                    store.getters.inviteFriendsModal.invitedFriends.includes(
                        uid
                    )
                )
                .map((uid: number) => {
                    return ProfileModel.fromId(uid);
                })
                .filter((profile: ProfileModel) => {
                    const q = this.query.toLowerCase();
                    return (
                        profile.firstName.toLowerCase().includes(q) ||
                        profile.lastName.toLowerCase().includes(q) ||
                        profile.username.toLowerCase().includes(q) ||
                        q.includes(profile.firstName.toLowerCase()) ||
                        q.includes(profile.lastName.toLowerCase()) ||
                        q.includes(profile.username.toLowerCase())
                    );
                });
        },
        friendsNotInvited: function () {
            return store.getters.users
                .get(1)
                .friends.filter(
                    (uid: number) =>
                        !store.getters.inviteFriendsModal.invitedFriends.includes(
                            uid
                        )
                )
                .map((uid: number) => {
                    return ProfileModel.fromId(uid);
                })
                .filter((profile: ProfileModel) => {
                    const q = this.query.toLowerCase();
                    return (
                        profile.firstName.toLowerCase().includes(q) ||
                        profile.lastName.toLowerCase().includes(q) ||
                        profile.username.toLowerCase().includes(q) ||
                        q.includes(profile.firstName.toLowerCase()) ||
                        q.includes(profile.lastName.toLowerCase()) ||
                        q.includes(profile.username.toLowerCase())
                    );
                });
        },
        query: {
            get(): string {
                return store.getters.inviteFriendsModal.query;
            },
            set(n: string) {
                store.dispatch("setInviteFriendsModalQuery", n);
            },
        },
    },
    methods: {
        dismiss: function () {
            store.dispatch("setInviteFriendsModalOpen", false);
        },
        invite: function (friend: ProfileModel) {
            store.dispatch("addInviteFriendsModalInvitedFriends", [friend.id]);
        },
        uninvite: function (friend: ProfileModel) {
            store.dispatch("removeInviteFriendsModalInvitedFriends", [
                friend.id,
            ]);
        },
    },
    setup() {
        return {
            close,
            checkmark,
        };
    },
});
