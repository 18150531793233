
import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
    useBackButton,
} from "@ionic/vue";
import {
    close,
    checkmark,
    globeOutline,
    musicalNotesOutline,
    wineOutline,
    personOutline,
} from "ionicons/icons";
import FatButton from "@/components/ui/FatButton.vue";
import FatButtonContainer from "@/components/ui/FatButtonContainer.vue";
import FatButtonFloat from "@/components/ui/FatButtonFloat.vue";
import { defineComponent } from "vue";
import store from "../store";
import { mapGetters } from "vuex";

export default defineComponent({
    name: "PartyModal",
    components: {
        IonModal,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButtons,
        IonButton,
        IonContent,
        IonImg,
        IonGrid,
        IonRow,
        IonCol,
        FatButton,
        FatButtonContainer,
        FatButtonFloat,
    },
    data() {
        return {
            content: "Content",
        };
    },
    computed: {
        ...mapGetters(["partyDetailsModal"]),
    },
    methods: {
        dismiss: function () {
            store.dispatch("setPartyDetailsModalOpen", false);
        },
        join: function () {
            store.dispatch(
                "setPartyJoinModalPartyModalRef",
                this.$refs.partyModal
            );
            store.dispatch(
                "setPartyJoinModalSelectedParty",
                this.partyDetailsModal.partyDetails
            );
            store.dispatch("setPartyJoinModalOpen", true);
        },
    },
    setup() {
        useBackButton(10, () => {
            store.dispatch("setPartyDetailsModalOpen", false);
        });
        return {
            close,
            checkmark,
            globeOutline,
            musicalNotesOutline,
            wineOutline,
            personOutline,
        };
    },
});
