import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04c111ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fat-button-container" }
const _hoisted_2 = {
  key: 1,
  class: "bald"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, {
    color: _ctx.color,
    expand: "block",
    class: "fat-button",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event))),
    fill: _ctx.secondary ? 'clear' : 'solid'
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.icon != null)
          ? (_openBlock(), _createBlock(_component_ion_icon, {
              key: 0,
              icon: _ctx.icon__,
              class: "fat-button-icon"
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true),
        (_ctx.text != null)
          ? (_openBlock(), _createElementBlock("b", _hoisted_2, _toDisplayString(_ctx.text), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["color", "fill"]))
}