
import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
    useBackButton,
} from "@ionic/vue";
import FatButton from "./ui/FatButton.vue";
import FatButtonContainer from "./ui/FatButtonContainer.vue";
import FatButtonFloat from "./ui/FatButtonFloat.vue";
import { close, checkmark, personAddOutline } from "ionicons/icons";
import { defineComponent } from "vue";
import store from "../store";
import { mapGetters } from "vuex";

export default defineComponent({
    name: "PartyJoinModal",
    components: {
        IonModal,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButtons,
        IonButton,
        IonContent,
        IonImg,
        IonGrid,
        IonRow,
        IonCol,
        FatButton,
        FatButtonContainer,
        FatButtonFloat,
    },
    data() {
        return {
            content: "Content",
        };
    },
    computed: {
        ...mapGetters(["partyJoinModal"]),
    },
    methods: {
        dismiss: function () {
            store.dispatch("setPartyJoinModalOpen", false);
        },
        inviteFriends: function () {
            store.dispatch(
                "setInviteFriendsModalPartyJoinModalRef",
                this.$refs.partyJoinModal
            );
            store.dispatch("setInviteFriendsModalOpen", true);
        },
    },
    setup() {
        return {
            close,
            checkmark,
            personAddOutline,
        };
    },
});
