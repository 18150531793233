
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import store from "@/store";

import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
} from "@ionic/vue";
export default defineComponent({
    name: "TownsModal",
    components: {
        IonModal,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButtons,
        IonButton,
        IonContent,
        IonList,
        IonItem,
        IonLabel,
    },
    computed: {
        ...mapGetters(["towns", "townsModal"]),
    },
    methods: {
        dismiss: function () {
            store.dispatch("setTownsModalOpen", false);
        },
        showTown: function (coords: number[], optimalZoom: number) {
            this.dismiss();
            store.dispatch("setTrendingPageCoords", coords);
            store.dispatch("setTrendingPageZoom", optimalZoom);
        },
    },
});
