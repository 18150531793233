
import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
    useBackButton,
} from "@ionic/vue";
import FatButton from "@/components/ui/FatButton.vue";
import FatButtonContainer from "@/components/ui/FatButtonContainer.vue";
import FatButtonFloat from "@/components/ui/FatButtonFloat.vue";
import { close, checkmark, globeOutline } from "ionicons/icons";
import { defineComponent } from "vue";
import store from "../store";
import router from "@/router";
import { mapGetters } from "vuex";
import PartyDisplay from "@/components/PartyDisplay.vue";

export default defineComponent({
    name: "PartyModal",
    components: {
        IonModal,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButtons,
        IonButton,
        IonContent,
        IonImg,
        IonGrid,
        IonRow,
        IonCol,
        FatButton,
        FatButtonContainer,
        FatButtonFloat,
        PartyDisplay,
    },
    data() {
        return {
            content: "Content",
        };
    },
    computed: {
        ...mapGetters(["placeDetailsModal"]),
        isFollowing: function () {
            return store.getters.followedPlaces.includes(
                store.getters.placeDetailsModal.placeDetails.id
            );
        },
    },
    methods: {
        dismiss: function () {
            store.dispatch("setPlaceDetailsModalOpen", false);
        },
        openMap: function () {
            console.log(router);
            router.push("/tabs/map");
            this.dismiss();
            setTimeout(() => {
                store.dispatch(
                    "setTrendingPageSelectedPlace",
                    this.placeDetailsModal.placeDetails
                );
            }, 500);
        },
    },
    setup() {
        useBackButton(10, () => {
            store.dispatch("setPlaceDetailsModalOpen", false);
        });
        return {
            close,
            checkmark,
            globeOutline,
        };
    },
});
