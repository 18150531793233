import ProfileModel from "./ProfileModel";

export default class ProfileModalModel {
    chosenProfile: ProfileModel
    presentingElement: any
    open: boolean

    constructor() {
        this.chosenProfile = new ProfileModel();
        this.open = false;
    }
}